import React from 'react';
import { Route, Switch, RouteProps } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './pages/home';
import { TransmissionData } from './pages/transmission/ListTransmission';
import './custom.css';
import { AddTransmissionPage } from './pages/transmission/AddTransmission';
import { EditTransmissionPage } from './pages/transmission/EditTransmission';
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from '@material-ui/core';
import { Unauthorized } from './components/unauthorized';
import { SurveyPage } from './pages/survey/Survey';
import { DemoPage } from './pages/demo/Demo';
import { TSPTheme } from './styles/theme';
import { TempPage } from './pages/temp/Temp';
import { AppChrome } from './components/app-chrome';
import { ErrorPage } from './pages/error/Error';
import { Help } from './pages/help';
import { RegisterPage } from './pages/register';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { LoginPage } from './pages/login';
import { PrivateRoute } from './components/reusable-components';
import {
  AccountCreatedPage,
  AccountUpdatedPage,
} from './pages/account-created/AccountCreated';
import { ResetPasswordPage } from './pages/reset-password/ResetPassword';
import { ForgotPasswordPage } from './pages/forgot-password/ForgotPassword';
import { SavedSpecPage } from './pages/saved-spec';
import { MySpecsPage } from './pages/my-specs/MySpecs';
import IdleTimer from 'react-idle-timer';
import AuthContextProvider, {
  AuthStatus,
  AuthDispatchContext,
  AuthStateContext,
} from './components/reusable-components/components/AuthContext';
import Cookie from 'js-cookie';
import { InactivePage } from './pages/inactive/Inactive';
import { NotFoundPage } from './pages/not-found/NotFound';

// Higher-Order Component to load Adobe Analytics script
const withAdobeAnalytics = <P extends object>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
  return class extends React.Component<P> {
    componentDidMount() {
      const script = document.createElement('script');
      script.src="https://assets.adobedtm.com/a98f37a3b42f/088fe54ee672/launch-db9e3f408ddc.min.js";
      script.async = true;
      document.head.appendChild(script);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

class App extends React.Component {
  idleTimer: IdleTimer | null;
  onIdle: (logout: () => void) => void | undefined;
  _onIdle(logout: () => void) {
    if (this.idleTimer) {
      logout();
    }
  }

  constructor(props: React.ComponentProps<any>) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  render() {
    return (
      <AuthStateContext.Consumer>
        {(stateValue) => (
          <AuthDispatchContext.Consumer>
            {(dispatchValue) => (
              <Layout>
                <MuiThemeProvider
                  theme={createMuiTheme(TSPTheme as ThemeOptions)}
                >
                  {dispatchValue &&
                    stateValue &&
                    stateValue.authenticated === AuthStatus.Authenticated && (
                      <IdleTimer
                        ref={(ref) => {
                          this.idleTimer = ref;
                        }}
                        element={document}
                        onIdle={(e) => this.onIdle(dispatchValue.timeout)}
                        debounce={250}
                        timeout={1000 * 60 * 60 * 1} // 1 hour
                      />
                    )}
                  <Switch>
                    <Route path='/login' component={withAdobeAnalytics(LoginPage)} />
                    <AppChrome>
                      <CssBaseline />
                      <Switch>
                        <Route exact path='/' component={withAdobeAnalytics(Home)} />
                        <PrivateRoute
                          exact
                          loginPath='/login'
                          allowedRoles={['TransmissionAdmin']}
                          path='/transmission-data'
                          component={withAdobeAnalytics(TransmissionData)}
                        />
                        <Route
                          exact
                          path='/created'
                          component={withAdobeAnalytics(AccountCreatedPage)}
                        />
                        <Route
                          exact
                          path='/updated'
                          component={withAdobeAnalytics(AccountUpdatedPage)}
                        />
                        <PrivateRoute
                          allowedRoles={['TransmissionAdmin']}
                          loginPath='/login'
                          path='/transmission-data/add'
                        >
                          <AddTransmissionPage />
                        </PrivateRoute>
                        <PrivateRoute
                          allowedRoles={['TransmissionAdmin']}
                          loginPath='/login'
                          path='/transmission-data/edit/:id'
                          component={withAdobeAnalytics(EditTransmissionPage)}
                        />
                        <PrivateRoute
                          exact
                          allowedRoles={[
                            'TransmissionAdmin',
                            'TransmissionUser',
                          ]}
                          loginPath='/login'
                          path='/spec'
                          component={withAdobeAnalytics(MySpecsPage)}
                        />
                        <PrivateRoute
                          allowedRoles={[
                            'TransmissionAdmin',
                            'TransmissionUser',
                          ]}
                          loginPath='/login'
                          path='/spec/:id'
                          component={withAdobeAnalytics(SavedSpecPage)}
                        />
                        <Route
                          path='/unauthorized'
                          component={withAdobeAnalytics(Unauthorized)}
                        />
                        <Route
                          path='/register'
                          component={withAdobeAnalytics(RegisterPage)}
                        />
                        <Route
                          path='/reset-password'
                          component={withAdobeAnalytics(ResetPasswordPage)}
                        />
                        <Route
                          path='/forgot-password'
                          component={withAdobeAnalytics(ForgotPasswordPage)}
                        />
                        <PrivateRoute
                          loginPath='/login'
                          path='/demo'
                          component={withAdobeAnalytics(DemoPage)}
                        />
                        <Route
                          path='/survey'
                          component={withAdobeAnalytics(SurveyPage)}
                        />
                        <Route
                          path='/privacy-policy'
                          component={(props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Privacy Policy. Please change once copy is provided.'
                              }
                            />
                          )}
                        />
                        <Route
                          path='/terms-and-conditions'
                          component={(props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Terms & Conditions. Please change once copy is provided.'
                              }
                            />
                          )}
                        />
                        <Route
                          path='/contact-us'
                          component={withAdobeAnalytics((props: RouteProps) => (
                            <TempPage
                              {...props}
                              text={
                                'This is a temporary page for Contact Us. Please change once copy is provided.'
                              }
                            />
                          ))}
                        />
                        <Route
                          path='/error'
                          component={withAdobeAnalytics(ErrorPage)}
                        />
                        <Route
                          path='/inactive'
                          component={withAdobeAnalytics(InactivePage)}
                        />
                        <Route path='/help' component={withAdobeAnalytics(Help)} />
                        <Route component={withAdobeAnalytics(NotFoundPage)} />
                      </Switch>
                    </AppChrome>
                  </Switch>
                </MuiThemeProvider>
              </Layout>
            )}
          </AuthDispatchContext.Consumer>
        )}
      </AuthStateContext.Consumer>
    );
  }
}

export default withAdobeAnalytics(App);